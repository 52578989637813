import React from 'react';
import { Logo } from '../../../../UI/components';

import './BrandLogo.scss';
import { NavLink } from 'react-router-dom';

export const BrandLogo = () => {
  return (
    <div className="BrandLogo d-flex justify-content-center container-fluid">
      <div className="row">
        <div className="col-12">
          <NavLink className="navbarBrandLogo" exact to="/">
            <Logo />
          </NavLink>
        </div>
        <div className="col-12 text-center">
          <p><a href="tel:1-204-688-8563"><i>Call or text Ken at 204-688-8563</i></a></p>
        </div>
      </div>
      
    </div>
  );
};
