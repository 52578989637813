import { getYear } from 'date-fns'
import { COMPANY_START_DATE } from '../../constants';

export const getCurrentYear = () => {
  return getYear(Date.now());
}

export const getYearsInService = () => {
  return getYear(Date.now()) - COMPANY_START_DATE;
}
