import React from 'react';
import Slider from 'react-slick';
import Img from 'react-image';
import imageSlidesArray from '../../assets/sliderImages';
import './ImageSlider.scss';

export const ImageSlider = () => {
  const settings = {
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: false,
    cssEase: 'ease-in-out',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    lazyLoad: 'progressive',
    pauseOnHover: false,
    fade: true
  };

  return (
    <div className="ImageSlider row">
      <Slider {...settings}>
        {imageSlidesArray.map(img => {
          return <Img key={img} src={img} />;
        })}
      </Slider>
    </div>
  );
};
