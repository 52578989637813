import Scroll from 'smooth-scroll';
import { MAIN_SECTION_ID } from '../../constants';

const scroll = new Scroll('.some-selector', {
  header: '[data-scroll-header]'
});
const selector = `#${MAIN_SECTION_ID}`;

export const ScrollToContent = () => {
  scroll.animateScroll(document.querySelector(selector));
};
