import React, { Component } from 'react';
import SideNav, { MenuIcon } from 'react-simple-sidenav';
import { BrandLogo, NavItems } from './components';
import { Navbar, Nav } from 'reactstrap';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';

export class Navigation extends Component {
  state = {
    showNav: false
  };

  closeNavigation = () => {
    this.setState({ showNav: false });
  };

  render() {
    return (
      <div className="Navigation row d-flex" data-scroll-header="">
        <Navbar expand="md" className="w-100">
          <Nav className="flex-grow-1 justify-content-center brandLogo">
            <li>
              <BrandLogo />
            </li>
          </Nav>
          <Nav navbar>
            <li className="d-none d-md-inline-flex">
              <NavItems />
            </li>
            <li className="d-inline-flex d-md-none MenuIcon">
              <MenuIcon onClick={() => this.setState({ showNav: true })} />
            </li>
          </Nav>
        </Navbar>

        <div className="SideNav">
          <SideNav
            className="SideNav"
            openFromRight={true}
            showNav={this.state.showNav}
            onHideNav={() => this.setState({ showNav: false })}
            title={<BrandLogo />}
            items={[<NavItems closeNavigation={this.closeNavigation} />]}
          />
        </div>
      </div>
    );
  }
}
