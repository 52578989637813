import React from 'react';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';

export const ClickHereButton = ({ link }) => {
  return (
    <NavLink className="ClickHereButton align-self-center" to={link}>
      <Button outline color="info">
        Click Here
      </Button>
    </NavLink>
  );
};
