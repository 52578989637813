import React from 'react';
import { CopyRight } from './components';
import { NavLink } from 'react-router-dom';

import './Footer.scss';

export const Footer = () => {
  const quickLinkArray = [];
  quickLinkArray.push(
    
  );
  quickLinkArray.push();

  return (
    <div className="Footer row d-flex justify-content-center pt-4">

      <div className="col-12 d-flex justify-content-center">
        <NavLink className="mx-3" exact to="/">
          Home
        </NavLink>
        <NavLink className="mx-3" to="/Contact-Us">Contact Us</NavLink>
      </div>

      <div className="col-12 text-center my-2">
        <CopyRight />
      </div>
    </div>
  );
};
