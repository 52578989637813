import React from 'react';
import { FadeLoader } from 'react-spinners';

export const Loading = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center my-5">
        <FadeLoader sizeUnit={'px'} size={20} color={'#e10001'} />
      </div>
    );
  }
  // Handle the error state
  else if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  } else {
    return null;
  }
};
