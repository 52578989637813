import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Container } from 'reactstrap';

import { Navigation } from './containers';
import { Footer, ImageSlider } from './components';
import { Loading } from './UI/components/';
import { MAIN_SECTION_ID } from './constants';

const asyncHome = Loadable({
  loader: () => import('./ReactRoutes/containers/Home/Home'),
  loading: Loading
});
// const asyncAboutUs = Loadable({
//   loader: () => import('./ReactRoutes/containers/AboutUs/AboutUs'),
//   loading: Loading
// });
// const asyncServices = Loadable({
//   loader: () => import('./ReactRoutes/containers/Services/Services'),
//   loading: Loading
// });
const asyncContactUs = Loadable({
  loader: () => import('./ReactRoutes/containers/ContactUs/ContactUs'),
  loading: Loading
});

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Container fluid className="Main">
            <Navigation />
            <ImageSlider />
            <div id={MAIN_SECTION_ID}>
              <Route exact path="/" component={asyncHome} />
              <Route path="/Contact-Us" component={asyncContactUs} />
              {/*<Route path="/About-Us" component={asyncAboutUs} />*/}
              {/*<Route path="/Services" component={asyncServices} />*/}
            </div>
            <Footer />
          </Container>
        </Router>
      </div>
    );
  }
}

export default App;
