import React from 'react';
import './Logo.scss';

export const Logo = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="Logo"
         width="878.000000pt" height="247.000000pt" viewBox="0 0 878.000000 247.000000"
         preserveAspectRatio="xMidYMid meet">
      
      <g transform="translate(0.000000,247.000000) scale(0.100000,-0.100000)"
         fill="#000000" stroke="none">
        <path d="M645 1945 c-249 -55 -322 -216 -162 -355 l38 -33 -81 -47 c-44 -25
-85 -51 -90 -56 -15 -15 67 -112 154 -182 105 -84 165 -102 358 -109 463 -17
677 176 441 397 -38 36 -61 65 -55 69 6 3 33 20 61 36 28 17 51 34 51 39 0 5
-38 46 -84 92 -131 132 -223 165 -456 163 -63 0 -142 -7 -175 -14z m329 -25
c83 -12 174 -51 212 -91 15 -16 26 -31 23 -33 -2 -2 -33 -22 -69 -43 l-66 -40
-32 22 c-50 34 -136 48 -264 43 -125 -6 -148 -12 -148 -38 0 -31 35 -39 330
-70 174 -18 279 -71 300 -151 39 -143 -138 -235 -445 -233 -147 2 -244 18
-313 53 -37 19 -112 85 -112 99 0 4 31 24 68 45 64 37 68 38 92 23 77 -47 107
-58 184 -66 99 -10 300 2 321 20 41 34 8 46 -191 70 -253 31 -357 64 -404 130
-79 111 17 226 216 259 70 12 217 12 298 1z"/>
        <path d="M725 1884 c-107 -16 -168 -38 -206 -74 -51 -48 -53 -86 -6 -135 47
-52 144 -83 303 -95 148 -12 201 -23 254 -50 45 -23 56 -45 35 -76 -22 -34
-103 -54 -245 -61 -142 -6 -250 11 -302 48 -30 22 -31 22 -65 3 l-35 -18 33
-28 c63 -53 114 -63 324 -63 171 0 197 2 264 23 122 38 167 101 124 172 -34
57 -126 87 -347 110 -206 21 -268 42 -274 92 -7 62 72 90 256 89 107 -1 132
-4 185 -25 l60 -25 30 21 30 21 -39 20 c-22 11 -61 26 -89 34 -52 14 -237 25
-290 17z"/>
        <path d="M1440 1856 l0 -105 48 -45 47 -46 123 0 122 0 0 -204 0 -204 54 -47
54 -46 108 3 109 3 3 248 2 247 170 0 170 0 0 102 0 101 -52 48 -53 49 -452 0
-453 0 0 -104z m870 -1 l0 -75 -170 0 -170 0 0 -250 0 -250 -80 0 -80 0 0 250
0 250 -170 0 -170 0 0 75 0 75 420 0 420 0 0 -75z"/>
        <path d="M1520 1855 l0 -35 170 0 170 0 0 -245 0 -245 35 0 35 0 0 245 0 245
168 2 167 3 0 30 0 30 -372 3 -373 2 0 -35z"/>
        <path d="M2580 1958 c0 -2 -1 -160 -1 -353 l0 -350 51 -47 51 -48 425 0 424 0
0 104 0 105 -55 48 -55 48 0 76 c0 56 -4 80 -15 89 -28 23 -15 30 55 30 l70 0
0 103 0 103 -48 47 -48 47 -427 0 c-235 0 -427 -1 -427 -2z m818 -100 l-3 -73
-313 -3 -313 -2 3 -48 3 -47 258 -3 257 -2 0 -75 0 -75 -261 0 -260 0 3 -47 3
-48 313 -3 312 -2 0 -75 0 -75 -395 0 -395 0 0 325 0 325 395 0 396 0 -3 -72z"/>
        <path d="M2660 1610 l0 -280 345 0 345 0 0 30 0 30 -310 0 -310 0 0 95 0 95
255 0 255 0 0 30 0 30 -255 0 -255 0 0 90 0 90 308 2 307 3 3 33 3 32 -345 0
-346 0 0 -280z"/>
        <path d="M3593 1953 c8 -14 192 -423 251 -558 50 -115 63 -136 114 -183 l57
-52 80 2 79 3 61 133 60 132 46 -92 c36 -71 59 -103 97 -135 50 -43 51 -43
130 -43 l79 0 61 138 c34 75 89 198 123 272 33 74 76 171 95 214 l35 80 -48
48 -47 48 -126 0 -127 0 -62 -132 -61 -131 -41 84 c-24 50 -59 103 -87 132
l-46 47 -86 0 -85 0 -60 -130 c-33 -71 -62 -130 -65 -130 -3 0 -18 33 -35 73
-20 50 -45 89 -84 130 l-54 57 -129 0 c-71 0 -127 -3 -125 -7z m273 -120 c25
-54 61 -135 81 -181 20 -45 39 -84 43 -87 4 -2 45 78 92 178 l85 182 60 3 59
3 84 -178 c46 -98 86 -181 89 -185 4 -4 45 75 91 177 l84 185 89 0 c87 0 88 0
81 -22 -10 -34 -266 -601 -277 -615 -5 -8 -32 -13 -64 -13 l-54 0 -73 153
c-40 83 -78 167 -86 185 -7 18 -17 31 -23 29 -5 -1 -47 -84 -93 -182 l-83
-180 -59 -3 -59 -3 -123 273 c-67 150 -133 297 -146 326 l-24 52 90 0 91 0 45
-97z"/>
        <path d="M3720 1877 c0 -8 17 -50 37 -93 20 -43 74 -162 119 -264 81 -182 82
-185 113 -185 30 0 33 4 104 155 40 86 87 185 104 220 l31 65 47 -100 c176
-371 161 -345 192 -345 28 0 32 8 147 263 65 144 121 270 124 280 3 14 -2 17
-32 17 -35 0 -38 -3 -63 -57 -15 -32 -61 -134 -103 -226 l-77 -168 -91 198
c-119 258 -113 247 -143 251 -24 3 -30 -7 -91 -135 -36 -76 -82 -177 -104
-225 -21 -48 -40 -85 -42 -84 -2 2 -48 102 -102 222 l-98 219 -36 3 c-26 2
-36 -1 -36 -11z"/>
        <path d="M5084 1628 c-142 -183 -264 -341 -273 -352 -15 -17 -13 -21 39 -67
l55 -49 137 0 137 0 57 74 57 73 193 2 192 2 76 -76 77 -75 139 0 c77 0 140 3
140 7 0 4 -8 16 -19 27 -10 12 -125 163 -256 336 -131 173 -263 341 -293 373
l-56 57 -72 0 -73 0 -257 -332z m621 -15 c132 -175 242 -321 243 -325 2 -4
-41 -8 -96 -8 l-100 0 -56 75 -56 75 -233 0 -232 0 -58 -75 -58 -75 -95 0
c-82 0 -95 2 -90 15 3 9 99 136 212 283 305 394 267 352 327 352 l52 -1 240
-316z"/>
        <path d="M5255 1723 c-197 -252 -295 -381 -295 -387 0 -3 19 -6 43 -6 41 1 44
3 92 70 l50 69 263 1 262 0 51 -70 c48 -66 54 -70 90 -70 22 0 39 3 39 7 0 3
-62 88 -137 187 -76 100 -169 223 -208 273 -56 74 -75 93 -95 93 -20 0 -49
-31 -155 -167z m259 -48 c53 -68 96 -127 96 -130 0 -3 -90 -5 -200 -5 -110 0
-200 3 -200 6 0 8 194 254 201 254 4 0 50 -56 103 -125z"/>
        <path d="M5355 1663 c-27 -36 -51 -70 -53 -74 -4 -10 201 -13 211 -3 5 6 -93
143 -103 143 -3 0 -27 -30 -55 -66z"/>
        <path d="M6160 1610 l0 -351 53 -49 52 -49 113 -1 112 0 0 125 0 125 119 0
119 0 78 -83 c44 -45 102 -101 130 -125 l51 -43 147 3 147 3 -130 140 -130
140 38 40 c21 22 44 51 51 64 19 37 17 139 -3 193 -23 59 -119 151 -197 187
l-55 26 -347 3 -348 3 0 -351z m720 296 c80 -42 110 -89 110 -171 0 -73 -28
-127 -80 -157 -22 -13 -40 -27 -40 -31 0 -5 53 -63 118 -129 64 -65 118 -123
120 -128 2 -4 -46 -8 -106 -9 l-108 -2 -43 48 c-24 26 -77 83 -118 126 l-75
77 -149 0 -149 0 0 -125 0 -125 -80 0 -80 0 0 326 0 325 323 -3 c292 -3 325
-5 357 -22z"/>
        <path d="M6247 1884 c-4 -4 -7 -130 -7 -281 l0 -273 35 0 34 0 3 123 3 122
182 3 181 2 118 -125 c116 -122 119 -125 161 -125 24 0 43 3 43 7 0 4 -51 58
-112 121 l-113 114 55 18 c101 34 140 110 104 199 -15 35 -30 52 -64 71 -45
25 -47 25 -330 28 -157 2 -289 0 -293 -4z m599 -97 c50 -58 15 -130 -69 -142
-23 -3 -136 -5 -252 -3 l-210 3 -3 87 -3 87 43 3 c24 2 139 1 256 -2 l214 -5
24 -28z"/>
        <path d="M6360 1729 l0 -50 213 3 c229 3 247 7 247 54 0 13 -9 28 -19 34 -12
6 -104 10 -230 10 l-211 0 0 -51z"/>
        <path d="M7237 1953 c-4 -3 -7 -49 -7 -102 l0 -96 54 -47 55 -48 115 0 116 0
0 -197 c0 -109 4 -203 8 -208 4 -6 29 -29 56 -53 l49 -42 108 0 109 0 0 250 0
250 170 0 170 0 0 103 0 103 -48 47 -48 47 -450 0 c-248 0 -454 -3 -457 -7z
m873 -98 l0 -75 -170 0 -170 0 0 -250 0 -250 -80 0 -80 0 0 250 0 250 -170 0
-170 0 0 75 0 75 420 0 420 0 0 -75z"/>
        <path d="M7316 1865 c-4 -14 -4 -30 -1 -35 4 -6 78 -10 173 -10 l167 0 0 -242
0 -243 35 0 35 0 -2 240 -1 240 171 5 172 5 0 30 0 30 -371 3 -372 2 -6 -25z"/>
        <path d="M2365 708 c-3 -8 -4 -79 -3 -158 l3 -145 25 0 c24 0 25 3 28 66 l3
67 102 -2 102 -1 3 -67 c3 -65 4 -68 28 -68 l25 0 -3 158 c-3 153 -4 157 -25
160 -21 3 -22 -1 -25 -70 l-3 -73 -102 -3 -102 -3 -3 73 c-3 67 -5 73 -26 76
-12 2 -24 -3 -27 -10z"/>
        <path d="M3045 574 c-44 -81 -81 -154 -83 -161 -2 -8 6 -13 21 -13 19 0 30 10
44 38 l20 37 100 3 100 3 18 -41 c15 -33 23 -40 47 -40 28 0 35 8 19 26 -5 5
-35 59 -67 119 -91 173 -89 170 -115 173 -21 2 -32 -12 -104 -144z m144 13
c17 -32 31 -60 31 -63 0 -2 -34 -4 -75 -4 l-76 0 37 70 c21 39 41 67 45 63 3
-5 20 -34 38 -66z"/>
        <path d="M3600 613 c0 -125 14 -165 70 -195 48 -26 157 -21 197 8 45 34 53 60
53 182 l0 112 -25 0 -25 0 0 -109 c0 -103 -1 -111 -26 -140 -23 -28 -32 -31
-79 -31 -42 0 -60 5 -82 24 -27 23 -28 28 -31 140 -4 115 -4 116 -28 116 -24
0 -24 -1 -24 -107z"/>
        <path d="M4225 708 c-3 -8 -4 -79 -3 -158 l3 -145 119 -3 c132 -3 149 0 140
23 -5 12 -25 15 -105 15 l-98 0 -3 138 c-3 134 -4 137 -26 140 -12 2 -24 -3
-27 -10z"/>
        <path d="M4770 560 l0 -160 25 0 25 0 0 160 0 160 -25 0 -25 0 0 -160z"/>
        <path d="M5130 559 l0 -160 27 3 27 3 -3 119 c-1 65 1 117 6 115 4 -2 58 -56
118 -119 65 -68 119 -116 133 -118 l22 -3 0 160 0 161 -25 0 -25 0 0 -115 c0
-63 -3 -115 -7 -115 -3 0 -55 52 -116 115 -80 85 -115 115 -133 115 l-24 0 0
-161z"/>
        <path d="M5854 701 c-131 -60 -130 -231 3 -287 52 -22 151 -18 203 9 l45 22 0
60 0 60 -83 3 c-76 3 -83 1 -80 -15 3 -14 15 -19 61 -21 l57 -3 0 -34 c0 -28
-5 -35 -31 -44 -45 -16 -125 -14 -159 4 -42 22 -60 53 -60 105 0 39 5 51 34
79 44 43 113 53 182 26 38 -14 48 -15 54 -4 31 50 -138 80 -226 40z"/>
      </g>
    </svg>
  
  
  )
};
